import "./styles.css";

import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CtaWpp from "../../components/CTAWpp";
import CtaTel from "../../components/CTATel";

import logo_topo from "../../assets/logo_topo.png";
import header2 from "../../assets/header2.png";

import header3_1 from "../../assets/header3_1.png";
import header3_2 from "../../assets/header3_2.png";
import header3_3 from "../../assets/header3_3.png";
import header3Mobile_1 from "../../assets/mobile/header3_1.png";
import header3Mobile_2 from "../../assets/mobile/header3_2.png";
import header3Mobile_3 from "../../assets/mobile/header3_3.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: header3_1,
  },
  {
    id: 2,
    image: header3_2,
  },
  {
    id: 3,
    image: header3_3,
  },
];

const dataMobile = [
  {
    id: 1,
    image: header3Mobile_1,
  },
  {
    id: 2,
    image: header3Mobile_2,
  },
  {
    id: 3,
    image: header3Mobile_3,
  },
];

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <figure className="logo">
            <img src={logo_topo} alt="Logo" />
          </figure>

          <h1 className="title">A ENERGIA QUE SEU NEGÓCIO PRECISA!</h1>

          <p className="text">
            Somos especialistas em manutenção, vendas e locação de
            <b> Nobreak’s e Baterias. </b>
          </p>

          <CtaWpp />
        </article>
      </section>

      <section className="mobile">
        <div className="container">
          <p className="text">
            Somos especialistas em manutenção, vendas e locação de
            <b> Nobreak’s e Baterias. </b>
          </p>

          <CtaWpp />
        </div>
      </section>

      <section className="header2">
        <article className="container">
          <div className="left">
            <h2 className="title">QUEM SOMOS</h2>

            <figure className="mobile">
              <img src={header2} alt="Linha de nobreaks" />
            </figure>

            <p className="text">
              A LB Nobreak's é uma empresa especializada na locação, venda e
              assistência técnica de equipamentos de proteção de energia. <br />{" "}
              <br />
              Dispomos de especialistas capacitados a desempenhar diversas
              atividades, abrangendo a manutenção preventiva, corretiva e
              suporte técnico 24horas.
            </p>

            <CtaWpp />
          </div>

          <figure className="right">
            <img src={header2} alt="Linha de nobreaks" />
          </figure>
        </article>
      </section>

      <section className="header3">
        <div className="container">
          <h2 className="title">NOSSAS SOLUÇÕES</h2>

          <p className="text">
            Nos destacamos no atendimento personalizado, ágil e eficaz.
          </p>

          <Swiper
            className="carousel"
            spaceBetween={20}
            slidesPerView={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? 1
                : 1
            }
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
              ? dataMobile.map(({ id, image }) => {
                  return (
                    <SwiperSlide key={id} className="img">
                      <figure>
                        <img src={image} alt={`Exemplo ${id} de Foto`} />
                      </figure>
                    </SwiperSlide>
                  );
                })
              : data.map(({ id, image }) => {
                  return (
                    <SwiperSlide key={id} className="img">
                      <figure>
                        <img src={image} alt={`Exemplo ${id} de Foto`} />
                      </figure>
                    </SwiperSlide>
                  );
                })}
          </Swiper>

          <p className="text2">
            <b>Solicite agora mesmo um orçamento por WhatsApp ou telefone:</b>
          </p>

          <div className="ctas">
            <CtaWpp text={"(11) 98609-5730"} />
            <CtaTel />
          </div>
        </div>
      </section>
    </header>
  );
};

export default Header;
