import "./styles.css";

import CtaWpp from "../../components/CTAWpp";
import CtaTel from "../../components/CTATel";

import about1 from "../../assets/about1.png";
import about2_1 from "../../assets/about2_1.png";
import about2_2 from "../../assets/about2_2.png";
import about2_3 from "../../assets/about2_3.png";
import about3 from "../../assets/about3.png";
import about3Mobile from "../../assets/mobile/about3.png";
import about4 from "../../assets/about4.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <div className="left">
            <h2 className="title">NOSSO DIFERENCIAL</h2>

            <p className="text">
              <b>A frente da Assistência Técnica.</b> <br /> <br />
              Realizamos visitas técnicas para análise e reparos de nobreak's e
              estabilizadores. Suporte técnico 24h à sua disposição. <br />
              <br />
              Atuamos nobreaks de pequeno, médio e grande porte. Com suporte
              desde da retirada até a instalação. <br />
              <br />
              Contrato de manutenção para que seu equipamento tenha melhor
              desempenho na proteção de energia oferecida. O contrato de
              manutenção preventiva, consiste no atendimento personalizado,
              visitas técnicas para análise, precisão, limpeza interna e externa
              do equipamento, troca e/ou reparo de peças
            </p>

            <figure className="mobile">
              <img
                src={about1}
                alt="Mullher sorrindo com uma prancheta na mão"
              />
            </figure>

            <CtaWpp />
          </div>

          <figure className="right">
            <img src={about1} alt="Mullher sorrindo com uma prancheta na mão" />
          </figure>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <h2 className="title">COMO PENSAMOS E FAZEMOS</h2>

          <p className="text">
            Agimos de forma profissional, com o objetivo de entregar as melhores
            soluções para nossos clientes.
          </p>

          <div className="gallery">
            <div className="item">
              <div className="top">
                <figure>
                  <img src={about2_1} alt="Missão" />
                </figure>

                <div className="text">Missão</div>
              </div>

              <p className="text">
                A missão da nossa empresa é proporcionar aos nossos clientes
                soluções completas para proteção de energia. Oferecemos produtos
                de alta qualidade e um serviço técnico especializado, sempre
                visando a satisfação do cliente. Acreditamos que a tecnologia
                pode contribuir significativamente para a melhoria da qualidade
                de vida das pessoas e estamos sempre buscando inovar para
                oferecer as melhores soluções do mercado.
              </p>
            </div>

            <div className="item">
              <div className="top">
                <figure>
                  <img src={about2_2} alt="Visão" />
                </figure>

                <div className="text">Visão</div>
              </div>

              <p className="text">
                Oferecermos aos nossos clientes as melhores soluções em termos
                de qualidade e custo-benefício, sempre buscando atender às suas
                necessidades com excelência. <br /> <br />
                Contamos com uma equipe experiente e altamente qualificada para
                garantir o melhor atendimento possível.
              </p>
            </div>

            <div className="item">
              <div className="top">
                <figure>
                  <img src={about2_3} alt="Valores" />
                </figure>

                <div className="text">Valores</div>
              </div>

              <p className="text">
                Acreditamos que a qualidade do produto e o atendimento
                personalizado ao cliente são os pilares para o crescimento
                sustentável da empresa. Por isso, investimos constantemente em
                treinamentos e aperfeiçoamentos técnicos, além de utilizar
                matérias-primas de alta qualidade. Tudo isso com o objetivo de
                oferecer produtos e serviços que atendam às necessidades dos
                nossos clientes com excelência.
              </p>
            </div>
          </div>

          <p className="text2">
            <b>Solicite agora mesmo um orçamento por WhatsApp ou telefone:</b>
          </p>

          <div className="ctas">
            <CtaWpp text={"(11) 98609-5730"} />
            <CtaTel />
          </div>
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <h2 className="title">POR QUE ESCOLHER A LB NOBREAK’S?</h2>

          <figure className="middle">
            <img
              src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? about3Mobile
                  : about3
              }
              alt="Atendimento 24H / Qualidade / Satisfação"
            />
          </figure>

          <CtaWpp />
        </div>
      </article>

      <article className="about4">
        <div className="container">
          <h2 className="title">FORMAS DE PAGAMENTO</h2>

          <p className="text">Aceitamos Pix e Boleto bancário</p>

          <figure>
            <img src={about4} alt="Pix / Boleto" />
          </figure>
        </div>
      </article>

      <article className="about5">
        <div className="container">
          <p className="text">
            <b>
              Tem alguma dúvida sobre nossos serviços? Fale conosco por WhatsApp
              ou telefone:
            </b>
          </p>

          <div className="ctas">
            <CtaWpp text={"(11) 98609-5730"} />
            <CtaTel />
          </div>
        </div>
      </article>
    </section>
  );
};

export default About;
